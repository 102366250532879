import React, { useState, useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Country } from "country-state-city";
import { validation } from "./validation";
import { Form } from "react-bootstrap";
import isEmpty from "../lib/isEmpty";
import { useParams, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import axios from "axios";
import { RegjoinNow, getRefUserName } from "../Action/ApiAction.js";
import { useSelector } from "react-redux";
import config from "../lib/config.js";
import IcoContractAbi from "../Abi/IcoContractAbi.json";
import BusdContractAbi from "../Abi/BusdContractAbi.json";
import XTRCABI from "../Abi/XTrcABI.json";
import Web3 from "web3";
import { toastAlert } from "../helper/toastAlert";
import {
  getUserDetails,
  getServerSideValidation,
  getusesettings
} from "../Action/ApiAction";

export default function Joinnow(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const countryDetails = Country.getAllCountries();
  const [validateError, setValidateError] = useState({});
  const [isLoader, setLoader] = useState(false);
  const [ip, setIP] = useState("");
  const [refId, setRefId] = useState(id);
  const [tokenpricevalue, settokenpricevalue] = useState(0);
  const [tokenpricevaluextrx, settokenpricevaluextrx] = useState(0);
  const [userdata, setuserdata] = useState();
  const [settings, setSetings] = useState();
  const [userName, setUserName] = useState("");
  const [bnbBal, setBnbBal] = useState();
  const [btnDisable, setBtnDisable] = useState(false);
  const Connection = useSelector((state) => state.walletConnection);

  var initialFormvalue = {
    Referralid: id,
    option: "",
    PlacementID: "",
    Nationality: "",
    amount: "",
    Username: "",
    TokenType: 1
  };
  const [formValue, setFormValue] = useState(initialFormvalue);
  const {
    Referralid,
    option,
    PlacementID,
    Nationality,
    amount,
    Username,
    TokenType
  } = formValue;

  console.log(Nationality, "tokenpricevalue");

  useEffect(() => {
    getData();
    getuserdata();
    getusesetting();
  }, [Connection.address]);

  const getusesetting = async () => {
    const data = await getusesettings();
    console.log(data, "___");
    setSetings(data?.result);
  };

  const getuserdata = async () => {
    if (Connection.address) {
      var reqdata = {
        address: Connection.address
      };

      const data = await getUserDetails(reqdata);
      console.log(data, "data123");
      if (data?.data?.success === true) {
        setuserdata(data?.data?.data);
        if (data?.data?.data?.communityOnly == 0) {
          navigate("/dashboard-community");
        } else {
          navigate("/dashboard-member");
        }
      }
    }
  };

  const getData = async () => {
    if (Connection.address) {
      const res = await axios.get("https://api.ipify.org/?format=json");
      console.log(res.data);
      setIP(res.data.ip);

      if (Connection?.address) {
        var web3 = new Web3(Connection?.web3);
        var icoContract = new web3.eth.Contract(
          IcoContractAbi,
          config.Icocontract
        );
        const tokenprice = await icoContract.methods.tokenPrice().call();
        const tokenprice1 = await icoContract.methods.tokenPrice1().call();
        console.log(tokenprice, "tokenprice", tokenprice1);
        var tokenvalue = tokenprice / 1e8;
        let tokenxtrc = tokenprice1 / 1e8;
        settokenpricevalue(tokenvalue);
        settokenpricevaluextrx(tokenxtrc);
      }
    }
  };

  var countryArr = [];
  countryDetails.map((data, i) => {
    // console.log(data, 'data');
    countryArr.push({
      value: data.phonecode,
      label: data.name,
      name: data.name,
      isoCode: data.isoCode
    });
  });

  const countryChange = async (e) => {
    console.log(e.target.value, "efefe");
    setFormValue({ ...formValue, ...{ Nationality: e.target.value } });
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    console.log(name, value, "name, value");
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);

    if (name == "Referralid") {
      const payload = {
        valueRef: value
      };
      const data = await getRefUserName(payload);
      console.log(data.data, "ewfcswefrdesf");
      if (data?.data?.success) {
        setUserName(data?.data?.user?.userName);
      } else {
        setUserName("No User");
      }
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setBtnDisable(true);
      console.log(formValue, "formValue");
      if (
        formValue.amount == "" ||
        formValue.amount == 0 ||
        formValue.amount < 0
      ) {
        toastAlert("error", "Enter valid amount field");
        setBtnDisable(false);
        return false;
      }

      if (
        formValue.amount < settings?.binaryInvestAmount ||
        formValue.amount % 10 !== 0
      ) {
        toastAlert(
          "error",
          `Amount must be multiples of 10 and greater than ${settings?.binaryInvestAmount}`
        );
        setBtnDisable(false);
        return false;
      }

      if (formValue.Referralid == "" || formValue.Referralid == undefined) {
        toastAlert(
          "warning",
          "Your referal id is null we use referal as default id " +
            config.defaultId
        );
        setBtnDisable(false);
        let formData = {
          ...formValue,
          ...{ ["Referralid"]: config.defaultId.toString() }
        };
        setFormValue(formData);
      }
      if (formValue.Username == "" || formValue.Username == undefined) {
        toastAlert("error", "Enter username field");
        setBtnDisable(false);
        return false;
      }

      if (formValue.option == "") {
        toastAlert("error", "Choose valid side field");
        setBtnDisable(false);
        return false;
      }
      if (formValue.Nationality == "") {
        toastAlert("error", "Select nationality field");
        setBtnDisable(false);
        return false;
      }
      // var ipaddress1 = ip.replaceAll(".", "");
      const payloadd = {
        amount: formValue.amount,
        Referralid: formValue.Referralid,
        Username: formValue.Username,
        option: formValue.option,
        Nationality: formValue.Nationality,
        PlacementID: formValue.PlacementID,
        ipaddress: ip
      };
      console.log(payloadd, "payloadd");
      var checkServer = await getServerSideValidation(payloadd);
      console.log(checkServer, "checkServer");
      if (checkServer?.data?.success == false) {
        toastAlert("error", checkServer?.data?.errors?.messages);
        setBtnDisable(false);
        return false;
      }

      setLoader(true);
      var web3 = new Web3(Connection.web3);
      // var bnbBal;
      const bnbbb = new web3.eth.getBalance(Connection.address)
        .then(async (valueBnb) => {
          if (valueBnb / 1e18 == 0) {
            toastAlert("error", "Your BNB Balance is low", "error");
            setBtnDisable(false);
            setLoader(false);
            return false;
          }

          var BusdBlanace = new web3.eth.Contract(
            BusdContractAbi,
            config.Busdaddress
          );
          var XtrcBlanace = new web3.eth.Contract(XTRCABI, config.xtrcaddress);
          const balance = await BusdBlanace.methods
            .balanceOf(Connection.address)
            .call();
          const balanceX = await XtrcBlanace.methods
            .balanceOf(Connection.address)
            .call();
          console.log(balance, "balance", amount * (1 / tokenpricevalue));
          if (Number(TokenType) == 1) {
            if (balance / 1e18 < amount * (1 / tokenpricevalue)) {
              toastAlert("error", "Your TAURAS Token Balance is low", "error");
              setLoader(false);
              setBtnDisable(false);
              return false;
            }
          } else {
            if (balanceX / 1e18 < amount * (1 / tokenpricevaluextrx)) {
              toastAlert("error", "Your XTAURAS Token Balance is low", "error");
              setLoader(false);
              setBtnDisable(false);
              return false;
            }
          }

          var icoContract = new web3.eth.Contract(
            IcoContractAbi,
            config.Icocontract
          );

          const tokenprice = await icoContract.methods.tokenPrice().call();

          var updateprice = tokenprice / 1e8;

          var approveamt = await convert(amount * 1e18);
          var TokenCont = "";

          if (Number(TokenType) == 1) {
            approveamt = approveamt * (1 / tokenpricevalue);
            TokenCont = BusdBlanace;
          } else {
            approveamt = approveamt * (1 / tokenpricevaluextrx);
            TokenCont = XtrcBlanace;
          }
          console.log(approveamt, "updateprice");
          approveamt = convert(approveamt);
          console.log(approveamt, "tokenprice");

          await TokenCont.methods
            .approve(config.Icocontract, approveamt.toString())
            .send({ gasLimit: 250000, from: Connection.address })
            .then(async (res) => {
              var optionside = option == "Left" ? 1 : 2;
              var ipaddress = ip.replaceAll(".", "");

              console.log(ipaddress, "ipaddress", Referralid);
              await icoContract.methods
                .registrationVinnex(
                  approveamt.toString(),
                  Referralid,
                  PlacementID === "" ? 0 : PlacementID,
                  optionside,
                  ipaddress,
                  Nationality,
                  TokenType.toString()
                )
                .send({ from: Connection.address })
                .then(async (res) => {
                  console.log(res, "resresres");
                  const reqdata = {
                    refaddress: Referralid,
                    side: option,
                    palcementaddress: PlacementID,
                    amount: amount,
                    country: Nationality,
                    address: Connection.address,
                    transactionHash: res.transactionHash,
                    ipaddress: ip,
                    userName: Username,
                    tokenType: TokenType
                  };
                  console.log(reqdata, "reqdata");
                  const result = await RegjoinNow(reqdata);
                  console.log(result, "result");

                  if (result.data.success == true) {
                    toastAlert("success", "Join successfully", "success");
                    setLoader(false);
                    setTimeout(() => {
                      // window.location.reload();
                      navigate("/dashboard-member");
                    }, 1000);
                  } else {
                    toastAlert("error", "Rejected", "error");
                    setBtnDisable(false);
                    setLoader(false);
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setLoader(false);
                  setBtnDisable(false);
                  console.log("Rejected Error", err);
                });
            })
            .catch((err) => {
              setLoader(false);
              setBtnDisable(false);
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          setBtnDisable(false);
          setLoader(false);
        });
    } catch (err) {
      console.log(err, "err");
      toastAlert("error", "Error", "error");
      setLoader(false);
      setLoader(false);
    }
  };

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }
  const validPositive = (e) => {
    if (
      new RegExp(`^\\d*(\\\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  return (
    <div>
      {isLoader == true && (
        <ReactLoading type={"spokes"} color={"#000000"} className="loading" />
      )}

      <div>
        <Navbar />

        <div className="joinnow">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="joinnow_box">
                  <h2 className="h2tag mb-5 text-center">Join Now</h2>
                  <label class="form-label d-block mt-4">Choose Token</label>
                  <div className="flx mb-4">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="TokenType"
                        defaultChecked
                        id="TRC"
                        value="1"
                        onChange={handleChange}
                      />
                      <label class="form-check-label" for="TRC">
                        TRC
                      </label>
                    </div>
                    <div class="form-check form-check-inline right">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="TokenType"
                        id="CTRC"
                        value="2"
                        onChange={handleChange}
                      />
                      <label class="form-check-label" for="CTRC">
                        XTRC
                      </label>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="Referralid" class="form-label">
                      Amount in USD
                    </label>

                    <input
                      type="text"
                      class="form-control"
                      name="amount"
                      value={amount}
                      id="amount"
                      aria-describedby="amount"
                      onInput={validPositive}
                      placeholder="USD Amount"
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    {validateError.amount && (
                      <p
                        className="error-message text-danger"
                        style={{
                          color: "red",
                          marginLeft: "25px",
                          marginTop: "10px"
                        }}
                      >
                        {validateError.amount}
                      </p>
                    )}{" "}
                  </div>
                  <div class="mb-3">
                    <label for="Referralid" class="form-label">
                      Amount in {TokenType == "1" ? "TRC" : "XTRC"}
                    </label>
                    {console.log(
                      amount,
                      tokenpricevalue,
                      "fvrdsvfrdsfvv",
                      TokenType,
                      typeof amount,
                      Number(amount) *
                        (1 / Number(TokenType) == "1"
                          ? tokenpricevalue
                          : tokenpricevaluextrx)
                    )}
                    <input
                      type="text"
                      class="form-control"
                      name="amount"
                      value={
                        TokenType == "1"
                          ? isNaN(
                              Math.round(Number(amount) * (1 / tokenpricevalue))
                            )
                            ? 0
                            : Math.round(Number(amount) * (1 / tokenpricevalue))
                          : isNaN(
                              Math.round(
                                Number(amount) * (1 / tokenpricevaluextrx)
                              )
                            )
                          ? 0
                          : Math.round(
                              Number(amount) * (1 / tokenpricevaluextrx)
                            )
                      }
                      id="amount"
                      aria-describedby="amount"
                      onInput={validPositive}
                      placeholder="TAURAS Amount"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="Referralid" class="form-label">
                      Referral ID
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="Referralid"
                      value={Referralid}
                      id="Referralid"
                      aria-describedby="Referralid"
                      placeholder="Referral ID"
                      onChange={handleChange}
                      onInput={validPositive}
                    />
                  </div>
                  {console.log(userName, "edefwedfce")}
                  {userName !== "No User" ? (
                    <span style={{ color: "green", float: "right" }}>
                      {userName}
                    </span>
                  ) : (
                    <span style={{ color: "red", float: "right" }}>
                      {userName}
                    </span>
                  )}
                  {/* <br /> */}
                  Default IDs :{" "}
                  <span
                    className="defaultIds"
                    onClick={async (e) => {
                      setFormValue({
                        ...formValue,
                        ...{ Referralid: config.defaultId }
                      });
                      const payload = {
                        valueRef: config.defaultId
                      };
                      const data = await getRefUserName(payload);
                      setUserName(data?.data?.user?.userName);
                    }}
                  >
                    {config.defaultId}
                  </span>{" "}
                  <span
                    className="defaultIds"
                    onClick={async (e) => {
                      setFormValue({
                        ...formValue,
                        ...{ Referralid: config.defaultId2 }
                      });
                      const payload = {
                        valueRef: config.defaultId2
                      };
                      const data = await getRefUserName(payload);
                      setUserName(data?.data?.user?.userName);
                    }}
                  >
                    {config.defaultId2}
                  </span>
                  <div>
                    {validateError.Referralid && (
                      <p
                        className="error-message text-danger"
                        style={{
                          color: "red",
                          marginLeft: "25px",
                          marginTop: "10px"
                        }}
                      >
                        {validateError.Referralid}
                      </p>
                    )}{" "}
                  </div>
                  <div class="my-3">
                    <label for="PlacementID" class="form-label">
                      Placement ID (Optional)
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="PlacementID"
                      value={PlacementID}
                      id="PlacementID"
                      aria-describedby="PlacementID"
                      placeholder="Placement ID"
                      onChange={handleChange}
                      onInput={validPositive}
                    />
                  </div>
                  <div>
                    {validateError.PlacementID && (
                      <p
                        className="error-message text-danger"
                        style={{
                          color: "red",
                          marginLeft: "25px",
                          marginTop: "10px"
                        }}
                      >
                        {validateError.PlacementID}
                      </p>
                    )}{" "}
                  </div>
                  <label class="form-label d-block">Choose position</label>
                  <div className="flx">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="option"
                        id="inlineRadio1"
                        value="Left"
                        onChange={handleChange}
                      />
                      <label class="form-check-label" for="inlineRadio1">
                        Left
                      </label>
                    </div>
                    <div class="form-check form-check-inline right">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="option"
                        id="inlineRadio2"
                        value="Right"
                        onChange={handleChange}
                      />
                      <label class="form-check-label" for="inlineRadio2">
                        Right
                      </label>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="Referralid" class="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="Username"
                      value={Username}
                      id="Username"
                      aria-describedby="Username"
                      placeholder="Username"
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    {validateError.Username && (
                      <p
                        className="error-message text-danger"
                        style={{
                          color: "red",
                          marginLeft: "25px",
                          marginTop: "10px"
                        }}
                      >
                        {validateError.Username}
                      </p>
                    )}{" "}
                  </div>
                  <div>
                    {validateError.option && (
                      <p
                        className="error-message text-danger"
                        style={{
                          color: "red",
                          marginLeft: "25px",
                          marginTop: "10px"
                        }}
                      >
                        {validateError.option}
                      </p>
                    )}{" "}
                  </div>
                  <label for="PlacementID" class="form-label">
                    Nationality
                  </label>
                  <Form.Select
                    onChange={countryChange}
                    style={{ cursor: "pointer" }}
                  >
                    <option style={{ background: "#111111" }} selected>
                      Select Country
                    </option>
                    {countryArr.map((country) => {
                      // console.log(country, 'country');
                      return (
                        <option value={country.isoCode}>{country.label}</option>
                      );
                    })}
                  </Form.Select>
                  <div>
                    {validateError.Nationality && (
                      <p
                        className="error-message text-danger"
                        style={{
                          color: "red",
                          marginLeft: "25px",
                          marginTop: "10px"
                        }}
                      >
                        {validateError.Nationality}
                      </p>
                    )}{" "}
                  </div>
                  <div class="my-3">
                    <button
                      disabled={btnDisable == true ? true : false}
                      // disabled={true}
                      className="primary_btn"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
}
