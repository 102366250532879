import React from "react"; 

const plus = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
  >
    <circle cx="9.5" cy="9.5" r="9.5" fill="#E9D16F" />
    <path d="M14 9V10H5V9H14Z" fill="#041E37" />
    <path
      d="M8.84686 5.23545L9.84671 5.21798L9.99985 13.9825L9 14L8.84686 5.23545Z"
      fill="#041E37"
    />
  </svg>
);

const minus = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
  >
    <circle cx="9.5" cy="9.5" r="9.5" fill="#E9D16F" />
    <path d="M12.0333 9.5V10.7667H6.96667V9.5H12.0333Z" fill="#041E37" />
  </svg>
);

const close = (props) => (
  <svg {...props} x="0px" y="0px" viewBox="0 0 416 416">
  </svg>
);

export { plus, minus, close };
