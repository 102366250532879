import React from "react";
import config from "../lib/config";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_left">
            <p>
              Smart-contract address:
              <a
                href={"https://testnet.bscscan.com/address/" + config.Icocontract}
                target="_blank"
              >
                {config.Icocontract}
              </a>
            </p>
            <p>
              Copy right © {new Date().getFullYear()} VinneX, All rights
              Reserved
            </p>
            <ul className="footer_menu_links">
              <li>
                <a href="#">Terms and conditions</a>
              </li>
            </ul>
          </div>
          <div className="footer_panel_right">
              {/* <ul className="footer_social_links">
                <li>
                  <a href="#" target="_blank">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i class="fa-brands fa-x-twitter"></i>
                  </a>
                </li>
              </ul> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
