import axios from "axios";
import config from "../lib/config";

export const RegjoinNow = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/Join-now`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const getRefUserName = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getRefUserName`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const retopUpbinary = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/retopUp-binary`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const getSignatureBalnce = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getSignatureBalnce`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const getSignature = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getSignature`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const getSignaturePrime = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getSignaturePrime`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {
    console.log(err);
  }
};

export const getSignatureCommu = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getSignatureCommu`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const getSignatureFuture = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getSignatureFuture`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const updateStatus = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/updateStatus`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const cancelStatusCommu = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/cancelStatusCommu`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const cancelStatus = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/cancelStatus`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {
    console.log(err);
  }
};
export const cancelPrimeStatus = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/cancelPrimeStatus`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};
export const cancelFutureStatus = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/cancelFutureStatus`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const updateRejeteStatus = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/updateRejeteStatus`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const cancelStatusBinary = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/cancelstatusbinary`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const getServerSideValidation = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getServerSideValidation`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const getServerSideValidationCommunity = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getServerSideValidationCommunity`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const userRegCommunity = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/user-Reg-Community`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const userRetopupCommunity = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/user-Retopup-Community`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const getUserDetails = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/get-User-Details`,
      data: payload
    });
    return {
      data: resp.data
    };
  } catch (err) {}
};

export const seaarchUserTree = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/searchUser`,
      data: data
    });
    return {
      user: respData.data,
      status: respData.data.status
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const getinvesmenthistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getinvesmenthistory`,
      data: data
    });
    return {
      result: respData.data.data,
      result1: respData.data.data1,
      status: respData.data.status
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const getBinaryHis = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getBinaryHis`,
      data: data
    });
    return {
      result: respData.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const AmountDeduction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/AmountDeduction`,
      data: data
    });
    return {
      result: respData.data
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const getWithdrawHis = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getWithdrawHis`,
      data: data
    });
    return {
      result: respData.data.data,
      result1: respData.data.data1,
      status: respData.data.status
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const getWithBalance = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/binaryHisLast220Days`,
      data: data
    });
    console.log(respData, "respData");
    return {
      result: respData.data.data,
      status: respData.data.status
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const gethomedetails = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/gethomedetails`
    });
    return {
      result: respData.data,
      status: respData.data.status
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const get_faq = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/get_faq`
    });
    return {
      result: respData.data.result,
      status: respData.data.status
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const get_Activities = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/get_Activities`
    });
    return {
      result: respData.data.result,
      status: respData.data.status
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const get_binaryhistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/get_binaryhistory`,
      data: data
    });
    return {
      result: respData.data.result,
      status: respData.data.status
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const get_communityhistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/get_communityhistory`,
      data: data
    });
    return {
      result: respData.data.result,
      result1: respData.data.result1,
      status: respData.data.status
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const getsettings = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/get_settings`
    });
    return {
      result: respData.data.result,
      status: respData.data.status
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};

export const getusesettings = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/get_use_settings`
    });
    return {
      result: respData.data.result,
      status: respData.data.status
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};
