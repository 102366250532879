import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import { Scrollbars } from "react-custom-scrollbars";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  gethomedetails,
  get_faq,
  get_Activities
} from "../Action/ApiAction.js";
import moment from "moment";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [data, setdata] = useState();
  const [faqdata, setfaqdata] = useState();
  const [activitydata, setactivitydata] = useState();

  useEffect(() => {
    AOS.init();
    getDetails();
    getActivities();
    faq();
  }, []);

  const faq = async () => {
    const { result, status } = await get_faq();
    if (status) {
      setfaqdata(result);
    }
  };

  const getActivities = async () => {
    const { result, status } = await get_Activities();

    const last3 = result.slice(-10);
    console.log(last3, status, "result, status");

    if (status) {
      setactivitydata(last3);
    }
  };

  const getDetails = async () => {
    const result = await gethomedetails();
    console.log(result, "result");
    if (result.status) {
      setdata(result.result);
    }
  };

  let navigate = useNavigate();

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="page_header">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                src={require("../assets/images/banner_img.png")}
                alt="Banner"
                className="img-fluid"
              />
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1>
                {" "}
                Unlocking the Future of Investment with Vinnex: Your Gateway to
                Wealth
              </h1>
              <h5>
                Welcome to Vinnex, where we redefine investment's essence. We're
                the future of decentralized, automated, and transparent
                investing, turning dreams into reality.
              </h5>
              <div className="banner_btn_grp">
                {/* <button className="primary_btn primary_btn_flx">
                  Learn More
                  <span className="primary_btn_arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <path
                        d="M9.66455 4.63121L6.19177 1.14606C6.09264 1.04658 5.96051 0.991974 5.81963 0.991974C5.67859 0.991974 5.54655 1.04666 5.44741 1.14606L5.13211 1.46256C5.03306 1.56189 4.97849 1.69456 4.97849 1.83602C4.97849 1.9774 5.03306 2.11454 5.13211 2.21387L7.15808 4.25148H0.721475C0.431269 4.25148 0.201965 4.47947 0.201965 4.77079V5.21823C0.201965 5.50954 0.431269 5.76052 0.721475 5.76052H7.18107L5.13219 7.80951C5.03313 7.909 4.97856 8.03806 4.97856 8.17952C4.97856 8.32082 5.03313 8.45176 5.13219 8.55117L5.44749 8.86664C5.54662 8.96613 5.67867 9.02034 5.81971 9.02034C5.96059 9.02034 6.09271 8.96542 6.19185 8.86594L9.66463 5.38087C9.764 5.28107 9.81864 5.14785 9.81825 5.00624C9.81857 4.86415 9.764 4.73085 9.66455 4.63121Z"
                        fill="#041E37"
                      />
                    </svg>
                  </span>
                </button> */}

                {walletConnection && walletConnection.address != "" ? (
                  <button
                    className="primary_btn primary_btn_flx"
                    onClick={() => navigate("/join-now")}
                  >
                    Join Now
                    <span className="primary_btn_arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <path
                          d="M9.66455 4.63121L6.19177 1.14606C6.09264 1.04658 5.96051 0.991974 5.81963 0.991974C5.67859 0.991974 5.54655 1.04666 5.44741 1.14606L5.13211 1.46256C5.03306 1.56189 4.97849 1.69456 4.97849 1.83602C4.97849 1.9774 5.03306 2.11454 5.13211 2.21387L7.15808 4.25148H0.721475C0.431269 4.25148 0.201965 4.47947 0.201965 4.77079V5.21823C0.201965 5.50954 0.431269 5.76052 0.721475 5.76052H7.18107L5.13219 7.80951C5.03313 7.909 4.97856 8.03806 4.97856 8.17952C4.97856 8.32082 5.03313 8.45176 5.13219 8.55117L5.44749 8.86664C5.54662 8.96613 5.67867 9.02034 5.81971 9.02034C5.96059 9.02034 6.09271 8.96542 6.19185 8.86594L9.66463 5.38087C9.764 5.28107 9.81864 5.14785 9.81825 5.00624C9.81857 4.86415 9.764 4.73085 9.66455 4.63121Z"
                          fill="#041E37"
                        />
                      </svg>
                    </span>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="banner_info_box_section">
          <div className="container">
            <div className="row">
              <div
                className="col-md-12"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="banner_info_box">
                  <div className="banner_info_box_single">
                    <img
                      src={require("../assets/images/icon_01.png")}
                      alt="Icon"
                      className="img-fluid"
                    />
                    <div>
                      <h2>
                        {data?.totalusercount == undefined
                          ? 0
                          : data?.totalusercount + 2000}
                      </h2>
                      <h3>All participants</h3>
                    </div>
                  </div>
                  <div className="banner_info_box_single">
                    {/* <img
                      src={require("../assets/images/icon_02.png")}
                      alt="Icon"
                      className="img-fluid"
                    /> */}
                    {/* <div>
                      <h2>
                        {data?.totalUSD == undefined
                          ? 0
                          : data?.totalUSD.toFixed(4)}
                      </h2>
                      <h3>Total earned USD</h3>
                    </div> */}
                  </div>
                  {/* <div className="banner_info_box_single">
                    <img
                      src={require("../assets/images/icon_03.png")}
                      alt="Icon"
                      className="img-fluid"
                    />
                    <div>
                      <h2>
                        {data?.totalUSDtwintyfourhours == undefined
                          ? 0
                          : data?.totalUSDtwintyfourhours}
                      </h2>
                      <h3>Total earned USD</h3>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section community_section">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 className="main_title">
                  Invest with TRC Tokens - Empower Your Wealth
                </h2>
                <p className="main_para">
                  At Vinnex, we don't just deal in investments; we deal in
                  possibilities. Your gateway to a prosperous future begins with
                  TRC tokens. These tokens are your key to unlock an investment
                  journey like no other. Whether you're an experienced investor
                  or just starting, Vinnex is designed to cater to your
                  financial aspirations.
                </p>
                {/* <p>
                  <strong>Communities Strength:</strong>
                </p>
                <ul className="custom_list">
                  <li>
                    No barriers to entry/exit, Adaptability, Stakeholder
                    alignment, Global access, Inclusive participation
                  </li>
                </ul> */}
              </div>
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <img
                  src={require("../assets/images/img_01.png")}
                  alt="Community Banner"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section feature_section">
          <div className="container">
            <div className="row">
              <div
                className="col-md-12"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 className="main_title text-center">
                  What we can do for you? <span>Features</span>
                </h2>
              </div>
            </div>
            <div className="row feature_section_row">
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="features_box">
                  <h2>The Power of Decentralization</h2>
                  <p className="main_para">
                    Amid centralized financial control, Vinnex shines with
                    decentralization, ensuring your investments are solely
                    yours, empowering you to pursue your financial goals.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="features_box">
                  <h2>Automation - Investing Made Effortless</h2>
                  <p className="main_para">
                    Automation at Vinnex simplifies investing, valuing your
                    time. Our cutting-edge system handles the work, letting you
                    enjoy growing investments stress-free.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="features_box">
                  <h2>Transparency for All</h2>
                  <p className="main_para">
                    Vinnex prioritizes trust through transparency. Our platform
                    offers real-time investment access and performance tracking,
                    eliminating guesswork and hidden fees. Stay
                    informed effortlessly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="section activity_section">
          <div className="container">
            <div className="row">
              <div
                className="col-md-12 col-lg-10 m-auto"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 className="main_title text-center">
                  Platform recent activity
                </h2>
                <div className="activity_panel">
                  <Scrollbars style={{ width: "100%", height: 450 }}>
                    <div className="activity_row">
                      <div className="activity_row_left">
                        <img
                          src={require("../assets/images/logo_small.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                        <img
                          src={require("../assets/images/user_icon.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                        <p className="activity_txt">New user joined</p>
                        <p className="activity_id">ID 1210781</p>
                      </div>
                      <div className="activity_row_right">
                        <a href="#" target="_blank">
                          <img
                            src={require("../assets/images/screen_icon.png")}
                            alt="Logo"
                            className="img-fluid"
                          />
                        </a>
                        <p className="activity_txt">1 minute</p>
                      </div>
                    </div>
                    <div className="activity_row">
                      <div className="activity_row_left">
                        <img
                          src={require("../assets/images/logo_small.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                        <img
                          src={require("../assets/images/user_icon.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                        <p className="activity_txt">New user joined</p>
                        <p className="activity_id">ID 1210781</p>
                      </div>
                      <div className="activity_row_right">
                        <a href="#" target="_blank">
                          <img
                            src={require("../assets/images/screen_icon.png")}
                            alt="Logo"
                            className="img-fluid"
                          />
                        </a>
                        <p className="activity_txt">1 minute</p>
                      </div>
                    </div>
                    <div className="activity_row">
                      <div className="activity_row_left">
                        <img
                          src={require("../assets/images/logo_small.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                        <img
                          src={require("../assets/images/wallet_icon.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                        <p className="activity_id">ID 1210781</p>
                        <p className="activity_txt">+40 BUSD in x4</p>
                      </div>
                      <div className="activity_row_right">
                        <a href="#" target="_blank">
                          <img
                            src={require("../assets/images/screen_icon.png")}
                            alt="Logo"
                            className="img-fluid"
                          />
                        </a>
                        <p className="activity_txt">1 minute</p>
                      </div>
                    </div>
                    <div className="activity_row">
                      <div className="activity_row_left">
                        <img
                          src={require("../assets/images/logo_small.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                        <img
                          src={require("../assets/images/wallet_icon.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                        <p className="activity_id">ID 1210781</p>
                        <p className="activity_txt">+40 BUSD in x4</p>
                      </div>
                      <div className="activity_row_right">
                        <a href="#" target="_blank">
                          <img
                            src={require("../assets/images/screen_icon.png")}
                            alt="Logo"
                            className="img-fluid"
                          />
                        </a>
                        <p className="activity_txt">1 minute</p>
                      </div>
                    </div>
                    <div className="activity_row">
                      <div className="activity_row_left">
                        <img
                          src={require("../assets/images/logo_small.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                        <img
                          src={require("../assets/images/user_icon.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                        <p className="activity_txt">New user joined</p>
                        <p className="activity_id">ID 1210781</p>
                      </div>
                      <div className="activity_row_right">
                        <a href="#" target="_blank">
                          <img
                            src={require("../assets/images/screen_icon.png")}
                            alt="Logo"
                            className="img-fluid"
                          />
                        </a>
                        <p className="activity_txt">1 minute</p>
                      </div>
                    </div>
                    <div className="activity_row">
                      <div className="activity_row_left">
                        <img
                          src={require("../assets/images/logo_small.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                        <img
                          src={require("../assets/images/user_icon.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                        <p className="activity_txt">New user joined</p>
                        <p className="activity_id">ID 1210781</p>
                      </div>
                      <div className="activity_row_right">
                        <a href="#" target="_blank">
                          <img
                            src={require("../assets/images/screen_icon.png")}
                            alt="Logo"
                            className="img-fluid"
                          />
                        </a>
                        <p className="activity_txt">1 minute</p>
                      </div>
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="section activity_section">
          <div className="container">
            <div className="row">
              <div
                className="col-md-12 col-lg-10 m-auto"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 className="main_title text-center">
                  Platform recent activity
                </h2>
                {activitydata &&
                  activitydata.map((item, index) => (
                    <div className="activity_panel">
                      <Scrollbars style={{ width: "100%", height: 100 }}>
                        <div className="activity_row">
                          <div className="activity_row_left">
                            <img
                              src={require("../assets/images/logo_small.png")}
                              alt="Logo"
                              className="img-fluid"
                            />
                            <img
                              src={require("../assets/images/user_icon.png")}
                              alt="Logo"
                              className="img-fluid"
                            />
                            <p className="activity_txt">
                              User {item?.type == 1 ? "Joined" : "Exit"}
                            </p>
                            <p className="activity_id">ID {item.userID}</p>
                          </div>
                          <div className="activity_row_right">
                            <a href="#" target="_blank">
                              <img
                                src={require("../assets/images/screen_icon.png")}
                                alt="Logo"
                                className="img-fluid"
                              />
                            </a>
                            <p className="activity_txt">
                              {moment(item.createdAt).format(
                                "MMMM,Do YYYY, hh:mm A"
                              )}
                            </p>
                          </div>
                        </div>
                      </Scrollbars>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>

        {/* <section className="section bg_dark plan_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-10 m-auto" data-aos="fade-up" data-aos-duration="1000">
                <h2 className="main_title text-center">Plan Explained</h2>
                <div className="row align-items-center mt-5">
                  <div className="col-lg-6">
                    <img
                      src={require("../assets/images/tree.png")}
                      alt="Plan"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="plan_detail_box">
                      <div className="plan_detail_box_top">
                        <div>
                          <h4> Matching Bonus</h4>
                          <h2>7%</h2>
                        </div>
                        <p>
                          (Direct Parent Referral 7% <br />
                          Second Parent 3% <br />
                          Third Parent 2%)
                        </p>
                      </div>
                      <div className="plan_detail_box_bottom">
                        <h2>ROI 1%</h2>
                        <p>(period 220 days)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/*faq static */}

        {/* <section className="section faq bg_dark">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-10 m-auto">
                <h2
                  className="main_title text-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  FAQ
                </h2>
                <div className="accordion" id="faq_accordian">
                  <div
                    className="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Q. What is VinneX?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#faq_accordian"
                    >
                      <div className="accordion-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Lorem ipsum dolor Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit, sed do eiusmod tempor
                          incididunt ut labore et dolore magna aliqua. Quis
                          ipsum suspendisse ultrices gravida.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="350"
                  >
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Q. How many levels of total income streams are there
                        with VinneX?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#faq_accordian"
                    >
                      <div className="accordion-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Lorem ipsum dolor Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit, sed do eiusmod tempor
                          incididunt ut labore et dolore magna aliqua. Quis
                          ipsum suspendisse ultrices gravida.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        Q. What is the minimum Staking Amount required to join
                        VinneX?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faq_accordian"
                    >
                      <div className="accordion-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Lorem ipsum dolor Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit, sed do eiusmod tempor
                          incididunt ut labore et dolore magna aliqua. Quis
                          ipsum suspendisse ultrices gravida.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="true"
                        aria-controls="collapseFour"
                      >
                        Q. How secure is my Staking with VinneX?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#faq_accordian"
                    >
                      <div className="accordion-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Lorem ipsum dolor Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit, sed do eiusmod tempor
                          incididunt ut labore et dolore magna aliqua. Quis
                          ipsum suspendisse ultrices gravida.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/*faq dinamic */}

        {console.log(faqdata, "faqdata")}
        <section className="section faq bg_dark">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-10 m-auto">
                <h2
                  className="main_title text-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  FAQ
                </h2>
                <div className="accordion" id="faq_accordian">
                  {faqdata &&
                    faqdata.map((item, index) => (
                      <>
                        {index}
                        <div
                          className="accordion-item"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-delay="200"
                        >
                          <h2
                            className="accordion-header"
                            id={`headingOne${index + 1}`}
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseOne${index + 1}`}
                              aria-expanded="false"
                              aria-controls={`collapseOne${index + 1}`}
                            >
                              Q. {item?.Question}
                            </button>
                          </h2>
                          <div
                            id={`collapseOne${index + 1}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`headingOne${index + 1}`}
                            data-bs-parent="#faq_accordian"
                          >
                            <div className="accordion-body">
                              <p>{item?.Answer}</p>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}
